var columns = [{
  title: '群头像',
  dataIndex: 'headimgurl',
  key: 'headimgurl',
  width: 140,
  scopedSlots: {
    customRender: 'headimgurl'
  },
  align: 'center'
}, {
  title: 'sendbird ID',
  dataIndex: 'sendbirdid',
  key: 'sendbirdid',
  width: 150,
  scopedSlots: {
    customRender: 'sendbirdid'
  },
  align: 'center'
}, {
  title: '群名称',
  dataIndex: 'name',
  key: 'name',
  width: 150,
  scopedSlots: {
    customRender: 'name'
  },
  align: 'center'
}, {
  title: '群主昵称',
  dataIndex: 'ownername',
  key: 'ownername',
  width: 150,
  scopedSlots: {
    customRender: 'ownername'
  },
  align: 'center'
}, {
  title: '群可被搜索',
  dataIndex: 'recommendswitch',
  key: 'recommendswitch',
  width: 110,
  scopedSlots: {
    customRender: 'recommendswitch'
  },
  align: 'center'
}, {
  title: '群规模',
  dataIndex: 'num',
  key: 'num',
  width: 100,
  sorter: true,
  scopedSlots: {
    customRender: 'num'
  },
  align: 'center'
}, {
  title: '7日平均日活',
  dataIndex: 'activenum',
  key: 'activenum',
  sorter: true,
  width: 140,
  scopedSlots: {
    customRender: 'activenum'
  },
  align: 'center'
}, {
  title: '7日日均发言人数',
  dataIndex: 'messagesnum',
  key: 'messagesnum',
  sorter: true,
  width: 160,
  scopedSlots: {
    customRender: 'messagesnum'
  },
  align: 'center'
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: 120,
  scopedSlots: {
    customRender: 'createtime'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: "right",
  width: 180,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
var signColums = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '15%',
  scopedSlots: {
    customRender: 'id'
  },
  align: 'center'
}, {
  title: '标识名称',
  dataIndex: 'name',
  key: 'name',
  width: '15%',
  scopedSlots: {
    customRender: 'name'
  },
  align: 'center'
}, {
  title: 'ICON',
  dataIndex: 'icon',
  key: 'icon',
  width: '20%',
  scopedSlots: {
    customRender: 'icon'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '15%',
  scopedSlots: {
    customRender: 'status'
  },
  align: 'center'
}, {
  title: '创建时间',
  dataIndex: 'createTime',
  key: 'createTime',
  width: '20%',
  scopedSlots: {
    customRender: 'createTime'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  width: 150,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
var recommendColums = [{
  title: '分类ID',
  dataIndex: 'id',
  key: 'id',
  width: '16%',
  scopedSlots: {
    customRender: 'id'
  },
  align: 'center'
}, {
  title: '分类时间',
  dataIndex: 'createTime',
  key: 'createTime',
  width: '16%',
  scopedSlots: {
    customRender: 'createTime'
  },
  align: 'center'
}, {
  title: '分类名',
  dataIndex: 'cnName',
  key: 'cnName',
  width: '14%',
  scopedSlots: {
    customRender: 'cnName'
  },
  align: 'center'
}, {
  title: '分类排序',
  dataIndex: 'sort',
  key: 'sort',
  width: '8%',
  scopedSlots: {
    customRender: 'sort'
  },
  align: 'center'
}, {
  title: '关联群数',
  dataIndex: 'num',
  key: 'num',
  width: '8%',
  scopedSlots: {
    customRender: 'num'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '10%',
  scopedSlots: {
    customRender: 'status'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  width: 240,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
var aiBotColums = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '16%',
  scopedSlots: {
    customRender: 'id'
  },
  align: 'center'
}, {
  title: '主问题',
  dataIndex: 'questions',
  key: 'questions',
  width: '14%',
  scopedSlots: {
    customRender: 'questions'
  },
  align: 'center'
}, {
  title: '答案',
  dataIndex: 'answer',
  key: 'answer',
  width: '8%',
  scopedSlots: {
    customRender: 'answer'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '10%',
  scopedSlots: {
    customRender: 'status'
  },
  align: 'center'
}, {
  title: '创建时间',
  dataIndex: 'createTime',
  key: 'createTime',
  width: '16%',
  scopedSlots: {
    customRender: 'createTime'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  width: 240,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns, signColums, recommendColums, aiBotColums };