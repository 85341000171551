var searchKeys = [{
  key: 'dates',
  label: '创建时间',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "ownername",
  label: '群主昵称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "sendbirdid",
  label: "sendbird ID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "name",
  label: '群名称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'iconid',
  label: '标识类型',
  placeholder: '请选择选择类型',
  required: false,
  rules: [],
  select: true
}, {
  key: 'recommendswitch',
  label: '可被搜索',
  placeholder: '请选择',
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeySign = [{
  key: 'dates',
  label: '创建时间',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "name",
  label: '名称',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'status',
  label: '状态',
  placeholder: '状态',
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeysRecommend = [{
  key: "name",
  label: '分类名称：',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'status',
  label: '状态',
  placeholder: '请选择状态',
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeyAiBot = [{
  key: 'dates',
  label: '创建时间',
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: 'status',
  label: '状态',
  placeholder: '状态',
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "question",
  label: '问题',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "answer",
  label: '答案',
  placeholder: '请输入',
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, searchKeySign, searchKeysRecommend, searchKeyAiBot };